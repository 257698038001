import _axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store/index'
import router from '@/router/index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import utils from '@/utils/utils'
// 错误码集
import errorCode from './errorCode'
const _request = _axios.create({
  // 基地址
  // baseURL: '//api.cvnplayboc.com' // 开发环境
  // baseURL: '//api.tomato-data.com' // 生产环境
  baseURL: process.env.VUE_APP_URL // 环境变量
  // baseURL: process.env.VUE_APP_TYPE
})

// 请求拦截
_request.interceptors.request.use(
  config => {
    if (store.state.token) {
      config.headers.Authorization = `Bearer ${store.state.token}`
    }
    const noPropressList = [
      '/chat/order/check-is-paid',
      '/chat/user/notices', // 获取通知列表
      '/chat/user/notices/read'// 已读通知
    ]
    if (!noPropressList.includes(config.url)) {
      // 进度条开始
      NProgress.start()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 响应拦截
_request.interceptors.response.use(
  res => {
    if (res.data.code !== 200) {
      Message({
        message: res.data.msg,
        type: 'error'
      })
    }
    NProgress.done()
    return res.data
  },
  error => {
    NProgress.done()
    // token 失效
    if (error.response.data.code === 401) {
      Message({
        message: '登录过期，请重新登录',
        type: 'error'
      })
      store.dispatch('signOut')
      if (!utils.isMobile()) {
        store.commit('login/setSign', 1)
        store.commit('login/setIsShow', true)
      } else {
        router.push('/login')
      }
      return Promise.reject(error)
    }
    // 多设备登录
    if (error.response.data.code === 1002) {
      store.dispatch('signOut')
      Message({
        message: '帐号在别的设备上登录了，请重新登录.',
        type: 'warning'
      })
      if (utils.isMobile()) {
        router.push('/login')
      } else {
        store.commit('login/setSign', 1)
        store.commit('login/setIsShow', true)
      }
      return Promise.reject(error)
    }
    if (error.response.data.code !== 200) {
      const data = errorCode.find((item) => error.response.data.code === Number(item.code))
      Message({
        message: data.message,
        type: 'error'
      })
    }
    return Promise.reject(error)
  }
)
export default _request
