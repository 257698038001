<template>
  <div class="yd-input hl-input">
      <input required="" type="text" v-if="!showPassword"  autocomplete="off" :class="{error:isError}" @blur="blur"  class="input" :value="value" @input="change" @keyup="$event.target.value = $event.target.value.replace(/\s+/g,'')" :placeholder="placeholder">
      <input required="" :type="isPass?'text':'password'" v-else autocomplete="off"  :class="{error:isError}" @blur="blur"  class="input" :value="value" @input="change" @keyup="$event.target.value = $event.target.value.replace(/\s+/g,'')" :placeholder="placeholder">
      <span class="highlight"></span>
      <span class="bar"></span>
      <label :class="{error:isError}">{{label}}</label>
      <p class="messageErr" v-if="isError">{{messageError}}</p>
      <i class="iconfont" :class="isPass?'icon-icon_zhengyanjing_1':'icon-icon-eye-close'"  v-if="showPassword" @click="isPass=!isPass"></i>
  </div>
</template>

<script>
export default {
  name: 'YDInput',
  model: {
    prop: 'value'
  },
  props: {
    value: {
      type: [String, Number]
    },
    label: {
      type: String,
      default: 'label'
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  inject: {
    formItem: {
      default: null
    },
    form: {
      default: null
    }
  },
  data () {
    return {
      isError: false,
      messageError: null,
      isPass: false
    }
  },

  methods: {
    // 失去焦点触发
    blur () {
      if (!this.formItem || !this.form) return
      this.validate(this.value)
    },
    change ($event) {
      if (this.disabled) return
      // console.log($event.target.value, 'value')
      this.$emit('input', $event.target.value)
      if (!this.formItem || !this.form) return
      this.validate($event.target.value)
    },
    // 表单验证
    validate (value) {
      if (!this.form.rule[this.formItem.prop]) return
      // console.log(this.value, 'value')
      try {
        this.form.rule[this.formItem.prop].forEach(({ required, max, min, message, validator }) => {
          // 是否填写
          if (required) {
            if (value === null || value === undefined || value === '') {
              this.isError = true
              this.messageError = message
              throw new Error('ending')
            }
          }
          // 支付长度是否达标
          if (max || min) {
            if (value.toString().length > max || value.toString().length < min) {
              this.isError = true
              this.messageError = message
              throw new Error('ending')
            }
          }
          // 自定义验证
          if (validator) {
            validator(value, (res) => {
              if (res) {
                this.isError = true
                this.messageError = res.message
                throw new Error('ending')
              } else {
                this.isError = false
              }
            })
          } else {
            this.isError = false
          }
          this.form.verifyCode.push(true)
        })
      } catch {
        this.form.verifyCode.push(false)
      }
    }
  },
  watch: {
    'form.testVerify' (val) {
      // 触发表单验证
      this.validate(this.value)
    },
    showPassword (val) {
      if (val) {
        this.isPass = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @import url('./input.less');
</style>
