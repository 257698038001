<template>
  <div id="app">
    <!-- 动态缓存 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" class="child-view"></router-view>
    </keep-alive>
    <!-- 路由出口 -->
    <router-view v-if="!$route.meta.keepAlive" />
    <!-- 登录组件 -->
    <Login />
  </div>
</template>

<script>
// import request from '@/utils/request'
import { chatWxOfficalLogin, chatUserInfo } from '@/api/login'
import utils from '@/utils/utils'
export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: 'home'
      },
      {
        name: 'description',
        content: 'This is the home page'
      }
    ]
  },
  components: {
    Login: () => import('@/components/login/login.vue')
  },
  data () {
    return {}
  },
  created () {
    // 聊天小助手 初始化
    // this.chat()
    // 默认mobile 不显示在线客服
    // window.$crisp.push(['config', 'hide:on:mobile', [true]])
    // 设置版本号
    this.$store.commit('setEdition', '1.0.8')
    localStorage.setItem('edition', '1.0.8')
    // 重新设置聊天格式
    const roomArr = JSON.parse(JSON.stringify(this.$store.state.AllchatRecord))
    roomArr.forEach((item, index) => {
      item.chatRecord.forEach((i, j) => {
        if (i.type === 'A') {
          const obj = {
            type: 'text',
            txt: i.message
          }
          if (typeof i.message === 'object') return
          roomArr[index].chatRecord[j].message = []
          roomArr[index].chatRecord[j].message.push(obj)
        }
      })
    })
    this.$store.commit('setAllchatRecord', roomArr)
    localStorage.setItem('AllchatRecord', JSON.stringify(roomArr))
    // if (utils.isMobile()) {
    //   // 获取广告链接
    //   let url = window.location.search
    //   let clickid = null
    //   if (/clickid/.test(url)) {
    //     url = url.split('&')
    //     url = url.find((item) => /clickid/.test(item))
    //     clickid = url.split('=')[1]
    //     console.log(clickid, 'clickid')
    //     this.advertisement(clickid)
    //   }
    // }
  },
  mounted () {
    // 监听子窗口传递的参数
    window.addEventListener('message', event => {
      if (event.data.isBinding) {
        this.$store.commit('user/setWxCode', event.data.wxCode)
        this.$store.commit('user/setIsBinding', event.data.isBinding)
      }
    })
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
    // 公众号登录
    publicSign (code) {
      // 开启移动端 登录等待
      this.$store.commit('login/setLoginLoad', true)
      chatWxOfficalLogin({
        code,
        channel: this.$route.query.cid,
        platform: 'offical-account'
      }).then((res) => {
        localStorage.setItem('token', res.data.token)
        this.$store.commit('setToken', res.data.token)
        if (utils.isMobile()) {
          this.$router.push('/chat_room')
        } else {
          this.$store.commit('login/setIsShow', false)
        }
      }).catch(() => {
        this.$store.commit('login/setLoginLoad', false)
      }).finally(() => {
        if (this.$store.state.token) {
          // 获取用户信息
          this.getUserInfo().then(() => {
            this.$store.commit('login/setLoginLoad', false)
          })
        }
      })
    },
    // 获取用户信息
    async getUserInfo () {
      const res = await chatUserInfo()
      localStorage.setItem('userInfo', JSON.stringify(res.data))
      this.$store.commit('setUserInfo', res.data)
    }
  },
  watch: {
    $route: {
      handler (val, oldval) {
        const url = window.location.search
        const location = window.location.href
        // 绑定微信code
        if (/state=wx/.test(url)) {
          // 关闭当前窗口
          window.close()
          const targetWindow = window.opener || window.parent
          targetWindow.postMessage(
            {
              isBinding: true,
              wxCode: url
                .split('&')[0]
                .split('?')[1]
                .split('=')[1]
            },
            window.location.href
          )
        }
        // 公众号登录
        if (/state=gzh/.test(url) && !this.$store.state.token) {
          let urlSearch = window.location.search.split('?')[1]
          urlSearch = urlSearch.split('&')
          let code = null
          urlSearch.forEach((item) => {
            if (/code/.test(item)) {
              code = item.split('=')[1]
            }
          })
          this.publicSign(code)
        }
        // 获取备案号
        if (/gptai.lingwl.cn/.test(location)) {
          this.$store.commit('setFilings', '黔ICP备2022009066号')
        } else if (/chat.fanwenct.com/.test(location)) {
          this.$store.commit('setFilings', '粤ICP备2022121883号')
        } else if (/chatai.shrwei3.top/.test(location)) {
          this.$store.commit('setFilings', '粤ICP备2023007511号')
        } else if (/chatgpt.shrwei3.top/.test(location)) {
          this.$store.commit('setFilings', '粤ICP备2023007511号')
        } else {
          this.$store.commit('setFilings', '粤ICP备18058968号')
        }
        if (val.query.invite_code) {
          // 分享邀请注册
          this.$store.commit('setInvitationCode', val.query.invite_code)
        }
        if (/cid=/.test(url)) {
          const strs = url.split('&')
          let str = ''
          strs.forEach((item, index) => {
            if (/cid/.test(item)) {
              str = item.split('=')[1]
            }
          })
          // 获取来源渠道
          this.$store.commit('setCid', str)
          localStorage.setItem('cid', str)
        }
        if (val.query.cid) {
          // 获取来源渠道
          this.$store.commit('setCid', val.query.cid)
          localStorage.setItem('cid', val.query.cid)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
#app {
}
</style>
