import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 清除浏览器默认样式
import './assets/css/initial.css'
// 导入element-ui
// import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 导入自定义字体
import './assets/font/font.css'
// 导入css
import utils from '@/utils/utils'
// 导入字体图标
import './assets/iconfont/iconfont.css'
import 'amfe-flexible'
import Vant from 'vant'
import 'vant/lib/index.css'
// 全局自定义组件
import './utils/components'
// SEO 优化
import MetaInfo from 'vue-meta-info'
import 'whatwg-fetch'
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// 动态导入css 样式
utils.isMobile()
  ? import('./assets/css/crisp.css')
  : import('./assets/css/initial.css')
Vue.component('mavonEditor', mavonEditor)
Vue.use(MetaInfo)
Vue.use(Vant)
// 注册使用
// Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
