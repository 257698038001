import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource'
import login from './login'
import mobile from './mobile'
import user from './user'
Vue.use(Vuex)
Vue.use(VueResource)
export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
    helpInfo: JSON.parse(localStorage.getItem('helpInfo')) || {},
    // 所有聊天记录总和
    AllchatRecord: JSON.parse(localStorage.getItem('AllchatRecord')) || [{
      id: '202301',
      chatRecord: [
      ],
      messageArr: [],
      chatMessage: 'chat room',
      prompt: '',
      isNot: true
    }
    ],
    // 当前处于哪个聊天室
    roomId: null,
    // 记录所有用户的聊天记录
    AllUserChatRecord: JSON.parse(localStorage.getItem('AllUserChatRecord')) || [],
    // 邀请码
    InvitationCode: null,
    // 来源渠道
    cid: localStorage.getItem('cid') || null,
    // 版本号
    edition: localStorage.getItem('edition') || null,
    // 版本
    version: 1, // 1 通用版 2 凡文版 // 3 百度推广
    // 备案号
    filings: ''
  },
  getters: {
  },
  mutations: {
    setToken (state, value) {
      state.token = value
    },
    setUserInfo (state, value) {
      state.userInfo = value
    },
    setHelpInfo (state, value) {
      localStorage.setItem('hlepInfo', JSON.stringify(value))
      state.helpInfo = value
    },
    // 存储聊天记录
    setAllchatRecord (state, value) {
      state.AllchatRecord = value
    },
    setRoomId (state, value) {
      state.roomId = value
    },
    setAllUserChatRecord (state, value) {
      state.AllUserChatRecord = value
    },
    setInvitationCode (state, value) {
      state.InvitationCode = value
    },
    setCid (state, value) {
      state.cid = value
    },
    setEdition (state, value) {
      state.edition = value
    },
    setFilings (state, value) {
      state.filings = value
    }
  },
  actions: {
    // 退出登录
    signOut ({ commit, state }) {
      commit('setUserInfo', '')
      commit('setToken', '')
      // 获取当前页面的 URL
      const urlString = window.location.href
      // 判断 URL 中是否有参数
      if (urlString.indexOf('?') !== -1) {
        // 如果有参数，则将参数部分清空
        const baseUrl = urlString.substring(0, urlString.indexOf('?'))
        window.history.replaceState(null, null, baseUrl)
      }
      localStorage.setItem('userInfo', null)
      localStorage.setItem('token', '')
      // const arr = state.AllUserChatRecord
      // const index = arr.findIndex((item) => item.userId === state.userInfo.id)
      // arr.splice(index, 1, state.AllchatRecord)
      // console.log(arr, 'arr')
      // localStorage.setItem('AllchatRecord', null)
      // localStorage.setItem('AllUserChatRecord', arr)
    }
  },
  modules: {
    login,
    mobile,
    user
  }
})
