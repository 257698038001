// 错误码集
const codeS = [
  {
    code: '401',
    message: '未经授权'
  },
  {
    code: '404',
    message: '未找到'
  },
  {
    code: '1000',
    message: '错误'
  },
  {
    code: '1001',
    message: '参数错误'
  },
  {
    code: '1002',
    message: '帐户已登录其他'
  },
  {
    code: '10001',
    message: '内容不能为空'
  },
  {
    code: '10002',
    message: '内容超过最大长度'
  },
  {
    code: '10003',
    message: '回复为空'
  },
  {
    code: '10004',
    message: '用户名无效'
  },
  {
    code: '10005',
    message: '账号或密码错误'
  },
  {
    code: '10006',
    message: '用户名已注册'
  },
  {
    code: '10007',
    message: '邮箱无效'
  },
  {
    code: '10008',
    message: '帐户无效'
  },
  {
    code: '10009',
    message: '手机无效'
  },
  {
    code: '10010',
    message: '验证码无效'
  },
  {
    code: '10011',
    message: '订单无效'
  },
  {
    code: '10012',
    message: '完成消息不能为空'
  },
  {
    code: '10013',
    message: 'paypal订单id无效'
  },
  {
    code: '10014',
    message: 'paypal订单审批失败'
  },
  {
    code: '10015',
    message: '令牌无效'
  },
  {
    code: '10016',
    message: '提示词token长度无效'
  },
  {
    code: '10017',
    message: '用户ID无效'
  },
  {
    code: '10018',
    message: '等级ID无效'
  },
  {
    code: '10019',
    message: '配置ID无效'
  },
  {
    code: '10020',
    message: '数据不存在'
  },
  {
    code: '10025',
    message: '验证码不能为空'
  },
  {
    code: '10026',
    message: '验证码无效'
  },
  {
    code: '20001',
    message: '请求openai失败'
  },
  {
    code: '20002',
    message: '当前IP注册已达上限'
  },
  {
    code: '20003',
    message: '用户不存在'
  },
  {
    code: '20004',
    message: '密码错误'
  },
  {
    code: '20005',
    message: 'vip级别不存在'
  },
  {
    code: '20006',
    message: '请求次数不足'
  },
  {
    code: '20007',
    message: '无法购买较低级别的vip'
  },
  {
    code: '20008',
    message: '创建订单失败'
  },
  {
    code: '20009',
    message: '创建付款失败'
  },
  {
    code: '20010',
    message: '创建订单失败'
  },
  {
    code: '20011',
    message: '支付结果错误'
  },
  {
    code: '20012',
    message: 'webhook顺序错误'
  },
  {
    code: '20013',
    message: '订单不存在'
  },
  {
    code: '20014',
    message: '订单付款金额错误'
  },
  {
    code: '20015',
    message: '太频繁地请求验证码'
  },
  {
    code: '20016',
    message: '发送验证码失败'
  },
  {
    code: '20017',
    message: '验证码代码错误'
  },
  {
    code: '20018',
    message: '电子邮件或手机已被占用'
  },
  {
    code: '20019',
    message: '暂时不支持'
  },
  {
    code: '20020',
    message: '订单状态错误'
  },
  {
    code: '20021',
    message: '订单创建付款重复'
  },
  {
    code: '20022',
    message: 'webhook验证签名失败'
  },
  {
    code: '20023',
    message: 'webhook失败'
  },
  {
    code: '20024',
    message: '无法关闭临时文件夹'
  },
  {
    code: '20025',
    message: '登录失败'
  },
  {
    code: '20026',
    message: '注册失败'
  },
  {
    code: '20027',
    message: '微信二维码授权失败'
  },
  {
    code: '20028',
    message: '微信扫码登录失败'
  },
  {
    code: '20029',
    message: '微信账号已经存在'
  },
  {
    code: '20030',
    message: '帐户异常'
  },
  {
    code: '20031',
    message: 'VIP注销失败'
  },
  {
    code: '20033',
    message: '您的注册太频繁'
  }
]
export default codeS
