export default {
  // 监测用户 设备是否未移动设备
  isMobile  () {
    return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      navigator.userAgent
    )
  },
  // 添零
  addZero (num) {
    if (num < 10 && num.toString().length === 1) {
      return `0${num}`
    } else {
      return num
    }
  },
  // 千分位符
  Thousands (num) {
    let str = null
    if (num) {
      str = num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    }
    return str || '0.00'
  }
}
